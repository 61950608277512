<template>
  <UikitDialog
    :model-value="modelValue"
    position="right"
    full-height
    content-class="osk-web-to-app-download-app-desktop-dialog"
    @update:model-value="closeDialog"
  >
    <div class="osk-web-to-app-download-app-desktop-dialog__content">
      <div class="text-heading osk-mt-xxxl">
        {{ title ?? $t('webToApp.downloadAppDesktopDialog.title') }}
      </div>

      <div class="osk-web-to-app-download-app-desktop-dialog__img-container">
        <div class="osk-web-to-app-download-app-desktop-dialog__img">
          <img
            v-if="!isInternational"
            src="~/assets/img/home/download-app.webp"
            srcset="~/assets/img/home/download-app.webp, ~/assets/img/home/download-app-2x.webp 2x"
            alt="download-app"
          >
          <img
            v-else
            src="~/assets/img/home/download-app-int.webp"
            alt="download-app"
          >
        </div>
      </div>

      <div>
        <div
          class="text-bold osk-mt-lg"
        >
          {{ $t('webToApp.downloadAppDesktopDialog.promoCodeText') }}
        </div>

        <i18n-t
          v-for="text in textList"
          :key="text"
          :keypath="`webToApp.downloadAppDesktopDialog.${text}`"
          class="osk-mt-lg"
          tag="div"
        >
          <template #prefix>
            <span class="text-bold">
              {{ $t(`webToApp.downloadAppDesktopDialog.${text}.prefix`) }}
            </span>
          </template>
        </i18n-t>
      </div>

      <div class="osk-mt-lg">
        <slot name="qr-code" />
      </div>
    </div>
  </UikitDialog>
</template>

<script lang="ts" setup>
defineProps<{
  modelValue: boolean,
  title?: string,
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
}>();

const { isInternational } = useInternational();
const textList = [ 'safeTransaction', 'onlyNewProducts', 'fastDelivery' ];

function closeDialog() {
  emit('update:modelValue', false);
}
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-web-to-app-download-app-desktop-dialog {
  width: 504px;
  padding: 24px 0;

  .osk-dialog__close {
    @include media-query(md-and-up) {
      top: 44px;
      right: 52px;
    }
  }

  &__content {
    width: 400px;
    margin: 0 auto;
  }

  &__img-container {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      border-radius: 2px;
      left: 0;
      right: 0;
      bottom: 0;
      background: url('../../assets/img/home/download-app-bg.webp') no-repeat;
      height: 208px;
      z-index: -1;
    }
  }

  &__img {
    img {
      width: 380px;
      height: 260px;
      object-fit: contain;
    }
  }
}
</style>
